import React, { useState } from "react";
import { IconButton, Popover, Paper, MenuList, MenuItem } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import classNames from "classnames";
import { Link } from "react-scroll";

import * as Styled from "./styles"

export default function MenusMobile(props: any) {
  const { className, menus } = props;
  const classes = Styled.useStyles();

  const [anchorEl, setAnchorEl] = useState<any>(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  return (
    <div className={classNames(classes.root, className)}>
      <IconButton
        aria-describedby={id} onClick={handleClick} color="secondary">
        <MenuIcon />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Paper className={classes.menu}>
          {menus.map((menu: any) => <MenuList key={menu.title}>
            <MenuItem onClick={handleClose} >
              <Link
                activeClass={classes.active}
                to={menu.route}
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                {menu.title}
              </Link>
            </MenuItem>
          </MenuList>)}
        </Paper>
      </Popover>
    </div>
  );
}
