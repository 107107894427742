import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  social: {
    display: "flex",
    flexDirection: "row",
    padding: 30,
    marginRight: 30,
    height: "100%",
    alignItems: "center"
  },
  icon: {
    marginRight: 10,
  },
  text: {
    color: theme.palette.common.white,
    marginRight: 20,
    marginBottom: "auto",
    marginTop: "auto",
  },
  copyrightContainer: {
    display: "flex",
    flexDirection: "row",
    padding: 30,
    height: "100%",
    alignItems: "center"
  },
  logo: {
    width: 55,
    height: 40,
    content: `url("${process.env.PUBLIC_URL}/short_white_logo.png")`,
  },
  copyrightText: {
    color: theme.palette.common.white,
    marginTop: "auto",
    marginBottom: "auto",
    marginLeft: theme.spacing(2),
  },
  footer: {
    minHeight: 200,
    width: "100%",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between'
  }
}));