import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { Element } from "react-scroll"

import * as Styled from "./styles"
import Service from "./service"
import { Routes } from "../../helpers/routes"

const Services = (props: any) => {
  const classes = Styled.useStyles()
  //digital-tachograph infinity aws battery-full cloud
  // dev keyboard laptop-code tasks
  // git git-alt git-square js-square node npm
  return (
    <Element name={Routes.Services}>
      <Grid container spacing={3} justify="center" alignItems="center" className={classes.container} >
        <Grid item className={classes.service} xs={12}>
          <Typography variant="h3" align="center">
            From the idea to success
          </Typography>
          <Typography variant="h4" align="center">
            We've got you covered
          </Typography>
        </Grid>
        <Grid item className={classes.service} md={4} sm={6}>
          <Service
            title="Agile Project Management"
            description="From the very first idea you have, throughout the development, to the final production, we accompany you using Agile methodologies. We adjust as you go along rather than following a linear path!"
            icon="git"
            iconVariant="brands" />
        </Grid>
        <Grid item className={classes.service} md={4} sm={6}>
          <Service
            title="Cloud Development"
            description="Want to migrate your legacy application to Clouds? Or even better, develop a cloud-first project? We're here for you to deploy your cost-efficient, scalable, custom cloud solutions. Just think Cloud!"
            icon="cloud"
            iconVariant="solid" />
        </Grid>
        <Grid item className={classes.service} md={4} sm={6}>
          <Service
            title="Custom Software Development"
            description="By uniting our tech expertise and knowledge, we develop tailor-made solutions for you. Whether you are a small, medium-sized or enterprise business, we will develop based on your unique demands."
            icon="laptop-code"
            iconVariant="solid" />
        </Grid>
        <Grid item className={classes.service} md={4} sm={6}>
          <Service
            title="Cost Optimization"
            description="Desire to benefit from the pay-as-go plans of clouds? Want to kick-start your project, pay less but profit from all the features? You'll have all our experiences and knowledge at your disposal!"
            icon="credit-card"
            iconVariant="solid" />
        </Grid>
        <Grid item className={classes.service} md={4} sm={6}>
          <Service
            title="High-Performance Deliverables"
            description="By using the best techniques and technologies and the best cloud services and resources based on your project, we deliver the highest-performance, scalable and fast projects that would cover you for life!"
            icon="battery-full"
            iconVariant="solid" />
        </Grid>
        <Grid item className={classes.service} md={4} sm={6}>
          <Service
            title="Maintenance and Support"
            description="Do you prefer to have us along after the launch? You can benefit from our technical support, software updates and optimization. Our support plans are customed and based on your specific needs."
            icon="tools"
            iconVariant="solid" />
        </Grid>
      </Grid>
    </Element>
  );
}

export default Services
