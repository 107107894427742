import React from "react";
import { Box } from "@material-ui/core";
import { Element } from "react-scroll"

import * as Styled from "./styles"
import Message from "./message"
import Info from "./info"
import { Routes } from "../../helpers/routes"

const Contact = (props: any) => {
    const classes = Styled.useStyles()

    return (
        <Element name={Routes.Contact}>
            <Box display="flex" alignItems="center" justifyContent="space-evenly" className={classes.container} >
                <Info />
                <Message />
            </Box>
        </Element>
    );
}

export default Contact
