import React from "react"
import { Box, Link } from "@material-ui/core"

import * as Styled from "./styles"
import { InlineSvg } from "../common/inline-svg"

export interface ClientProps {
  src?: string
  alt: string
  linkUrl: string
  height: number
}

const Client = (props: ClientProps) => {
  const { src, alt, linkUrl, height } = props
  const classes = Styled.useStyles()

  return (
    <Box display="flex" flexDirection="column" alignItems="center" padding={5}>
      <Link href={linkUrl} target="_blank">
        <InlineSvg className={classes.logo} src={src} alt={alt} height={height} />
      </Link>
    </Box>
  )
}

export default Client
