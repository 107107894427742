import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { Element } from "react-scroll"

import * as Styled from "./styles"
import TeamMemberCard from "./team-member-card"
import { Routes } from "../../helpers/routes"

const Team = (props: any) => {
  const classes = Styled.useStyles()

  return (
    <Element name={Routes.Team}>
      <Grid container spacing={3} justify="center" alignItems="center" className={classes.container}>
        <Grid item className={classes.card} xs={12}>
          <Typography variant="h3" align="center" className={classes.whiteText}>
            A strong team of software engineers
          </Typography>
          <Typography variant="h4" align="center" className={classes.whiteText}>
            to guide you through success
          </Typography>
        </Grid>
        <Grid item className={classes.card}>
          <TeamMemberCard
            alt="Bardia Navvabian"
            name="Bardia Navvabian"
            title="Co-Founder, CEO, Cloud Solutions Architect"
            description="As an AWS certified practinioner, Bardia specializes in AWS Solutions Architecture and Development. He will employ his experience and knowledge to get the best outcome."
            src={require(`./images/bardia.jpg`)}
            linkedInUrl="https://www.linkedin.com/in/bardia-navvabian/"
            certificateBardgeId="a8d5c58c-bb0b-4d81-84f1-8ca7649736d9" />
        </Grid>
        <Grid item className={classes.card}>
          <TeamMemberCard
            alt="Mahsa Maslahati"
            name="Mahsa Maslahati"
            title="Co-Founder, Team Lead"
            description="As a senior full-stack developer, Mahsa would lead and develop alongside with the team throughout the project's life-cycle using Agile methods."
            src={require(`./images/mahsa.jpg`)}
            linkedInUrl="https://www.linkedin.com/in/mahsa-maslahati/"
            doubleTopMargin
            doubleBottomMargin />
        </Grid>
        <Grid item className={classes.card}>
          <TeamMemberCard
            alt="Vahid Jafari"
            name="Vahid Jafari"
            title="Senior Backend Developer"
            description="As a senior backend developer, Vahid's greatest passion is helping organizations to build well-designed, high performance and scalable applications."
            src={require(`./images/vahid.jpg`)}
            linkedInUrl="http://www.linkedin.com/in/vahid-jafari1983/"
            doubleTopMargin
            doubleBottomMargin />
        </Grid>
      </Grid>
    </Element>
  );
}

export default Team
