import React from "react";
import { Typography, Box } from "@material-ui/core";

import * as Styled from "./styles"

const Info = (props: any) => {
    const classes = Styled.useStyles()

    return (
        <Box display="flex" flexDirection="column" justifyContent="center">
            <Typography variant="h4" color="secondary" className={classes.textMargin}>
                Do you have questions?
            </Typography>
            <Typography variant="h6" color="primary">
                Please email us at:
            </Typography>
            <Typography variant="body1" color="primary" className={classes.textMargin}>
                info@proboard.fr
            </Typography>
            <Typography variant="h6">
                Or mail us at:
            </Typography>
            <Typography variant="body1">
                2bis Rue Tête d'Or
            </Typography>
            <Typography variant="body1">
                69006 Lyon, France
            </Typography>
        </Box>
    );
}

export default Info
