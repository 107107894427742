import React from "react";
import { Typography, Fab } from "@material-ui/core";

import FontAwesomeIcon from "../common/FontAwesomeIcon";
import * as Styled from "./styles"

export default function FooterSocial() {
  const classes = Styled.useStyles()

  return (
    <div className={classes.social}>
      <Typography variant="subtitle2" className={classes.text}>
        Follow us
      </Typography>
      <Fab
        size="small"
        className={classes.icon}
        href="https://www.linkedin.com/company/sarlproboard/"
        target="_blank"
      >
        <FontAwesomeIcon icon="linkedin" size="lg" />
      </Fab>
      <Fab
        size="small"
        className={classes.icon}
        href="https://www.facebook.com/agileproboard/"
        target="_blank"
      >
        <FontAwesomeIcon icon="facebook" size="lg" />
      </Fab>
    </div>
  );
}
