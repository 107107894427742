import React from "react";
import classNames from "classnames";
import * as Styled from "./styles"
import { Link } from "react-scroll";

export default function MenusLink(props: any) {
  const classes = Styled.useStyles()
  const { className, menus } = props;

  return (
    <nav className={classNames(classes.navItems, className)} id="navbar">
      <ul className={classes.appBar}>
        {menus.map((menu: any) =>
          <li className={classes.navItem} key={menu.title}>
            <Link
              activeClass={classes.active}
              to={menu.route}
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              autoFocus={menu.autoFocus} >
              {menu.title}
            </Link>
          </li>
        )}
      </ul>
    </nav>
  );
}
