import React from "react";
import { Typography, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Element } from "react-scroll"

import { Routes } from "../helpers/routes"

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundImage: `url("${process.env.PUBLIC_URL}/home_background.jpg")`,
    width: "100%",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    minHeight: `900px`,
  },
  text: {
    color: theme.palette.common.white,
  },
}));

export default function Home(props: any) {
  const classes = useStyles();

  return (
    <Element name={Routes.Home}>
      <Box className={classes.root} display="flex" flexDirection="column" alignItems="center" margin="auto" justifyContent="center" >
        <Typography variant="h1" className={classes.text}>
          Proboard
        </Typography>
        <Typography variant="h4" className={classes.text} align="center">
          Agile & Value-Driven way of Software Development!
        </Typography>
      </Box>
    </Element>
  );
}
