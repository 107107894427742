import React from "react"
import { Box, Typography } from "@material-ui/core"
import classNames from "classnames"

import FontAwesomeIcon from "../common/FontAwesomeIcon";
import * as Styled from "./styles"

export interface ServiceProps {
  icon: string
  title: string
  description: string
  iconVariant?: "solid" | "regular" | "light" | "brands"
}

const Service = (props: ServiceProps) => {
  const { description, title, icon, iconVariant } = props
  const classes = Styled.useStyles()

  return (
    <Box display="flex" flexDirection="column" alignItems="center" padding={5}>
      <FontAwesomeIcon icon={icon} size="3x" color="secondary" variant={iconVariant} />
      <Typography variant="h5" align="center" className={classNames(classes.textMargin, classes.textWeight)}>{title}</Typography>
      <Typography variant="body1" align="center" className={classes.textMargin}>{description}</Typography>
    </Box>
  )
}

export default Service
