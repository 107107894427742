import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    background: `repeating-linear-gradient(to bottom, ${theme.palette.primary.dark} , ${theme.palette.secondary.main})`,
    width: "100%",
    minHeight: `900px`,
    margin: "auto"
  },
  client: {
    padding: theme.spacing(0)
  },
  logo: {
    width: `auto`,
    margin: `0 auto`,
    filter: "brightness(0) invert(1)"
  },
  title: {
    padding: theme.spacing(10),
    color: theme.palette.common.white,
  }
}));