import React from "react";
import { Typography } from "@material-ui/core";
import * as Styled from "./styles"

export default function FooterCopyRight() {
  const classes = Styled.useStyles()

  return (
    <div className={classes.copyrightContainer}>
      <img className={classes.logo} alt="logo-footer" />
      <Typography className={classes.copyrightText} variant="caption">
        Copyright © 2022 Proboard S.A.R.L. France. All rights reserved.
      </Typography>
    </div>
  );
}
