import React from "react";
import { Router, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import promise from "redux-promise-middleware";
import thunk from "redux-thunk";
import { createLogger } from "redux-logger";

import browserHistory from "./helpers/history-helper";
import rootReducer from "./reducers";
import Layout from "./App/Layout";
import { GlobalStyles } from "./theme";
import ProtectedRoute from "./helpers/protected-route";
import { Routes } from "./helpers/routes";

const middlewares = [promise, thunk]
document.title = process.env.REACT_APP_TITLE || `Proboard`
if (process.env.NODE_ENV === `development`) {
  middlewares.push(
    createLogger({
      collapsed: true,
    }),
  )
}

const middleware = applyMiddleware(...middlewares)
const store = createStore(rootReducer, middleware);

function App() {
  return (
    <Provider store={store}>
      <GlobalStyles>
        <Router history={browserHistory}>
          <Switch>
            <ProtectedRoute path={Routes.Landing} component={Layout} />
          </Switch>
        </Router>
      </GlobalStyles>
    </Provider>
  );
}

export default App;
