import * as React from "react"
import { makeStyles, Fab } from "@material-ui/core";
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { animateScroll } from "react-scroll";

const useStyles = makeStyles((theme) => ({
  fab: {
    position: "fixed",
    bottom: theme.spacing(3),
    right: theme.spacing(3),
  },
}))

const ScrollToTop: React.FC = () => {
  const classes = useStyles()

  const scrollToTop = () => {
    animateScroll.scrollToTop();
  };

  return (
    <Fab className={classes.fab} color="secondary" onClick={scrollToTop} size="small">
      <KeyboardArrowUpIcon />
    </Fab>
  );
}

export default ScrollToTop;
