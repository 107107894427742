import React from "react"
import { Paper, Box, Typography, Fab, Link } from "@material-ui/core"
import classNames from "classnames"

import FontAwesomeIcon from "../common/FontAwesomeIcon"
import * as Styled from "./styles"

export interface TeamMemberCardProps {
  alt: string
  src: string
  name: string
  title: string
  description: string
  linkedInUrl: string
  doubleTopMargin?: boolean
  doubleBottomMargin?: boolean
  certificateBardgeId?: string
}

const TeamMemberCard = (props: TeamMemberCardProps) => {
  const { alt, src, description, title, name, linkedInUrl, doubleTopMargin, doubleBottomMargin, certificateBardgeId } = props
  const classes = Styled.useStyles()

  return (
    <Paper elevation={6} className={classes.paper}>
      <Box display="flex" flexDirection="column" alignItems="center" padding={5}>
        <div className={classes.avatar}>
          <img alt={alt} src={src} className={classes.img} />
        </div>
        {/* <Avatar alt={alt} src={src} className={classes.avatar} /> */}
        <Typography variant="h4" align="center" className={classes.textMargin}>{name}</Typography>
        <Typography variant="h5" align="center" className={classes.textMargin}>{title}</Typography>
        <Typography variant="body1" align="center" className={classNames(classes.textMargin, { [classes.doubleTextTopMargin]: doubleTopMargin }, { [classes.doubleTextBottomMargin]: doubleBottomMargin })}>{description}</Typography>
        <Box display="flex" flexDirection="row" justifyContent="space-evenly" marginTop={1} width="100%" alignItems={"center"}>
          <Fab
            size="small"
            href={linkedInUrl}
            target="_blank" color="primary" >
            <FontAwesomeIcon icon="linkedin" size="lg" color="textSecondary" />
          </Fab>
          {certificateBardgeId &&
            <Link
              href={`https://www.credly.com/badges/${certificateBardgeId}/public_url`}
              target="_blank"
              className={classes.certificateImg}>
              <img alt="AWS Certificate" src={require(`./images/aws-certificate.png`)} className={classes.certificateImg} />
            </Link>
          }
        </Box>

      </Box>
    </Paper>
  )
}

export default TeamMemberCard
