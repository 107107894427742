import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import classNames from "classnames"

const useStyles = makeStyles((theme) => ({
  primary: {
    color: theme.palette.primary.main,
  },
  secondary: {
    color: theme.palette.secondary.main,
  },
  darkPrimary: {
    color: theme.palette.primary.dark,
  },
  textPrimary: {
    color: theme.palette.text.primary,
  },
  textSecondary: {
    color: theme.palette.text.secondary,
  },
}))

export interface FontAwesomeIconProps {
  variant?: "solid"| "regular"| "light"| "brands"
  icon: string
  color?: "primary"|"secondary"|"darkPrimary"|"textPrimary"|"textSecondary"
  className?: string
  size?: "xs"| "sm"| "lg"| "2x"| "3x"| "5x"| "7x"| "10x"
}

const FontAwesomeIcon = (props: FontAwesomeIconProps) => {
  const { variant = "brands", icon, color= "primary", className, size="sm" } = props
  const classes = useStyles()

  return (
    <i
      className={classNames(
        `fa${variant[0]}`,
        `fa-${icon}`,
        { [classes[color]]: color },
        { [`fa-${size}`]: size },
        className,
      )}
    />
  )
}

export default FontAwesomeIcon
