import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  appBar: {
    height: 80,
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  navItems: {
    marginRight: 50,
    width: "60%",
  },
  menus: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  logo: {
    width: 70,
    display: "block",
    content: `url("${process.env.PUBLIC_URL}/white_logo.png")`,
  },
  linkLogo: {
    marginTop: "auto",
    marginBottom: "auto",
    marginLeft: theme.spacing(5),
    "&:hover": {
      cursor: "pointer",
    },
  },
  menusMobile: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  root: {
    right: 15,
    top: 15,
    position: "absolute",
    zIndex: 1,
  },
  menu: {
    width: 200,
  },
  active: {
    color: theme.palette.secondary.main
  },
  navItem: {
    display: "block",
    textTransform: "none",
    fontSize: "medium",
    fontWeight: "bold",
    "&:hover": {
      cursor: "pointer",
      color: theme.palette.secondary.main
    },
  }
}));
