import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    minHeight: `900px`,
    margin: "auto",
    marginTop: theme.spacing(7)
  },
  service: {
    padding: theme.spacing(10)
  },
  textMargin: {
    marginTop: theme.spacing(4)
  },
  textWeight: {
    fontWeight: 600
  }
}));