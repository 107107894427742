import React from "react"
import { Paper, Box, TextField, Button } from "@material-ui/core"

import * as Styled from "./styles"

const Message = (props: any) => {
    const classes = Styled.useStyles()

    return (
        <Paper elevation={6} className={classes.paper}>
            <form action="mailto:info@proboard.fr" method="GET" className={classes.form}>
                <Box display="flex" flexDirection="column" alignItems="center" padding={5}>
                    <TextField
                        name="subject"
                        label="Your Request"
                        placeholder="Type Your Request Here"
                        variant="outlined"
                        color="secondary"
                        fullWidth
                        margin="dense"
                        InputProps={{ style: { color: "#fff" } }}
                    />
                    <TextField
                        name="body"
                        label="Message"
                        placeholder="Type Your Message Here"
                        variant="outlined"
                        multiline
                        rows={13}
                        color="secondary"
                        fullWidth
                        margin="dense"
                        InputProps={{ style: { color: "#fff" } }} />
                    <Button
                        type="submit"
                        variant="contained"
                        color="secondary"
                        size="large"
                        fullWidth
                        className={classes.button}>
                        Email us
                    </Button>
                </Box>
            </form>
        </Paper>
    )
}

export default Message
