const ActionTypes = {
  REDIRECT_HOME_PAGE: "REDIRECT_HOME_PAGE",
};

const HomePage = () => {
  return {
    type: ActionTypes.REDIRECT_HOME_PAGE,
  };
};

export { ActionTypes, HomePage };
