import * as React from "react"
import { Route } from "react-router-dom"

interface Props {
  path: string
  render?: Function
  component?: Object
}

const ProtectedRoute = (props: Props) => {
   return <Route {...(props as any)} />
}

export default ProtectedRoute
