import * as React from "react"
import {
  createMuiTheme,
  CssBaseline,
  MuiThemeProvider,
} from "@material-ui/core"
import { green, red } from "@material-ui/core/colors";

const setTheme = () => {
  return createMuiTheme({
    typography: {
      fontFamily: ["Arial", "Helvetica", "sans-serif"].join(","),
    },
    palette: {
      primary: {
        main: "rgba(9, 42, 75)", //"rgba(47, 51, 70)",
        light: "rgba(68, 115, 176)",
        dark: "rgba(29, 51, 81)",
        contrastText: "#fff",
      },
      secondary: {
        main: "#4dd0e1", //"rgba(207, 111, 102)",
        dark: "#009faf",//red[600],
        light: "#88ffff",//red["A100"],
        contrastText: "#fff",
      },
      success: {
        ...green,
        main: green[500],
        light: green[300],
        dark: green[700],
        contrastText: "#fff",
      },
      error: {
        main: red[800],
        light: "#ff5f52",
        dark: "#8e0000",
        contrastText: "#fff",
      },
      warning: {
        main: red[800],
        light: "#ff5f52",
        dark: "#8e0000",
        contrastText: "#fff",
      },
      // background: {
      //   default: "rgba(47, 51, 70)",
      //   // inputText: "#fff",
      // },
      text: {
        primary: "rgba(47, 51, 70)",
        secondary: "#fff",
      },
    },
    //   overrides: {
    //     MuiButton: {
    //       contained: {
    //         "&:disabled": {
    //           opacity: 0.7,
    //           background: amber[600],
    //         },
    //       },
    //     },
    //     MuiInputBase: {
    //       input: {
    //         color: "black",
    //       },
    //     },
    //   },
  })
}

interface GlobalStyles {
  children: React.ReactNode
}
/**
 * MuiThemeProvider @see https://material-ui.com/customization/themes/#muithemeprovider
 * CssBaseline @see https://material-ui.com/style/css-baseline/
 */
export const GlobalStyles = React.memo((props: GlobalStyles) => {
  const theme = setTheme()

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      {props.children}
    </MuiThemeProvider>
  )
})
