import React from "react";

import FooterCopyRight from "./footer-copyright";
import FooterSocial from "./footer-social";
import * as Styled from "./styles"

export default function Footer(props: any) {
  const classes = Styled.useStyles()

  return (
    <footer className={classes.footer}>
      <FooterCopyRight />
      <FooterSocial />
    </footer>
  );
}
