import * as React from "react"

export interface InlineSvg extends React.HTMLProps<HTMLDivElement> { }
export const InlineSvg = React.memo(({ className, alt, src, width, height }: InlineSvg) => {

  return (
    <img
      className={className}
      src={src}
      alt={alt}
      width={width}
      height={height}
    />
  )
})
