import React from "react"
import { AppBar } from "@material-ui/core"
import { Link } from "react-scroll";

import Menus from "./menus"
import MenusMobile from "./menus-mobile"
import ConnectRedirects from "../../connectors/connect-redirects"
import { Routes } from "../../helpers/routes";
import * as Styled from "./styles"

const MyAppBar = (props: any) => {
  const classes = Styled.useStyles()

  const menus = [
    { title: "Home", route: Routes.Home, autoFocus: true },
    { title: "Our Team", route: Routes.Team },
    { title: "Services", route: Routes.Services },
    { title: "Our Clients", route: Routes.Clients },
    { title: "Contact Us", route: Routes.Contact }
  ]

  return (
    <AppBar position="fixed" className={classes.appBar} color="primary">
      <Link to={Routes.Home} className={classes.linkLogo} spy={true}
        smooth={true}
        offset={-70}
        duration={500}>
        <img className={classes.logo} alt="logo" data-testid="logo" />
      </Link>
      <Menus className={classes.menus} menus={menus} />
      <MenusMobile className={classes.menusMobile} menus={menus} />
    </AppBar>
  )
}

export default ConnectRedirects(MyAppBar)
