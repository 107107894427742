import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    backgroundImage: `url("${process.env.PUBLIC_URL}/blue_background.jpg")`,
    width: "100%",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    minHeight: `900px`,
    margin: "auto"
  },
  card: {
    padding: theme.spacing(10)
  },
  paper: {
    width: 400,
    height: 600
  },
  avatar: {
    width: theme.spacing(20),
    height: theme.spacing(20),
    display: "flex",
    overflow: "hidden",
    alignItems: "center",
    flexShrink: 0,
    borderRadius: "50%",
    justifyContent: "center"
  },
  img: {
    color: "transparent",
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  textMargin: {
    marginTop: theme.spacing(4)
  },
  whiteText: {
    color: theme.palette.common.white,
  },
  doubleTextTopMargin: {
    marginTop: theme.spacing(8)
  },
  doubleTextBottomMargin: {
    marginBottom: theme.spacing(3.5)
  },
  certificateImg: {
    width: 50,
    height: 50,
    filter: "drop-shadow(2px 2px 2px rgba(0,0,0,0.1))"
  },
}));