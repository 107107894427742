import * as React from "react"
import { makeStyles } from "@material-ui/core";
import { animateScroll } from "react-scroll"

import AppBar from "./app-bar";
import Home from "./home";
import Team from "./team";
import Services from "./services";
import Clients from "./clients";
import Contact from "./contact"
import Footer from "./footer";
import ScrollToTop from "./scroll-to-top"

const useStyles = makeStyles({
  main: {
    marginTop: 80,
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
  },
  layout: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
  }
})

const Layout: React.FC = () => {
  const classes = useStyles()

  React.useEffect(() => {
    animateScroll.scrollToTop()
  }, [])

  return (
    <div className={classes.layout}>
      <AppBar />
      <main className={classes.main}>
        <Home />
        <Team />
        <Services />
        <Clients />
        <Contact />
      </main>
      <ScrollToTop />
      <Footer />
    </div>
  );
}

export default Layout;
