import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { Element } from "react-scroll"

import * as Styled from "./styles"
import Client from "./client"
import vomelaIcon from "./images/vomela-logo.svg"
// import fcfIcon from "./images/fcf-logo.svg"
import cometIcon from "./images/comet-logo.svg"
import medicalibIcon from "./images/medicalib-logo.svg"
import vertIcon from "./images/vertrun-logo.png"
import holiviaIcon from "./images/holivia-logo.png"
import { Routes } from "../../helpers/routes"

const Clients = (props: any) => {
  const classes = Styled.useStyles()

  return (
    <Element name={Routes.Clients}>
      <Grid container spacing={4} justify="center" alignItems="center" className={classes.container} >
        <Grid item className={classes.title} xs={12}>
          <Typography variant="h4" align="center">
            Our company helps many clients.
          </Typography>
          <Typography variant="h4" align="center">
            From startups to medium-sized businesses and large enterprises.
          </Typography>
          <Typography variant="h4" align="center">
            With a focus on optimization and expansion regardless of the domain they work in.
          </Typography>
        </Grid>
        <Grid item className={classes.client}>
          <Client
            src={cometIcon} alt="Comet" height={50} linkUrl="https://www.comet.co/" />
        </Grid>
        <Grid item className={classes.client}>
          <Client
            src={medicalibIcon} alt="Medicalib" height={80} linkUrl="https://www.medicalib.fr/" />
        </Grid>
        <Grid item className={classes.client}>
          <Client
            src={holiviaIcon} alt="Holivia" height={50} linkUrl="https://www.holivia.fr/" />
        </Grid>
        <Grid item className={classes.client}>
          <Client
            src={vertIcon} alt="Vertrun" height={100} linkUrl="https://app.vert.run" />
        </Grid>
        <Grid item className={classes.client}>
          <Client
            src={vomelaIcon} alt="Vomela Companies" height={54} linkUrl="https://vomela.com" />
        </Grid>
        {/* <Grid item className={classes.client}>
          <Client
            src={fcfIcon} alt="FirstClassFlyer" height={30} linkUrl="https://firstclassflyer.com" />
        </Grid> */}

      </Grid>
    </Element>
  );
}

export default Clients
