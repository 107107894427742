import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    minHeight: `900px`,
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column"
    },
  },
  paper: {
    width: 500,
    height: 500,
    padding: 20,
    background: `repeating-linear-gradient(to left, ${theme.palette.primary.light}, ${theme.palette.primary.dark})`,
  },
  form: { height: "100%" },
  button: { marginTop: 20 },
  info: {
    display: "flex",
    justifyContent: "center",
  },
  message: {
    display: "flex",
    justifyContent: "center",
  },
  textMargin: {
    marginBottom: 30
  }
}));